import React, { Fragment, useState } from "react";
import { createUseStyles } from "react-jss";
import ModalProductOption from "./ModalProductOption";
import axios from "axios";
import { Notyf } from "notyf";
import loader from "../../../../assets/svg/spinner.svg"; //https://loading.io/spinner/spinner/

const notyf = new Notyf({
  position: {
    x: "center",
    y: "bottom",
  },
  duration: 0, //illimitée
  dismissible: true,
});

const useStyles = createUseStyles({
  ModalButton: {
    backgroundColor: "transparent !important",
    color: "white",
    opacity: "0.8",
    fontSize: "1.5rem",
    transition: "all 200ms ease-in",
    cursor: "pointer",
    "&:hover": {
      transform: "scale(1.1)",
      opacity: "1",
    },
    "&:focus": {
      borderStyle: "none !important",
    },
  },
});

const ModalButton = ({ segment }) => {
  const classes = useStyles();

  const [openModal, setOpenModal] = useState(false);
  const [segmentWithStock, setSegmentWithStock] = useState(null); // État pour stocker les données
  const [loading, setLoading] = useState(false); // État pour gérer le chargement

  const toggle = () => {
    if (!openModal) {
      //au clic sur "Je choisis", on enrichit le segment de produits choisi par son stock
      setLoading(true);

      const loginConfig = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}login_check`,
        data: JSON.stringify({
          username: process.env.REACT_APP_EVE_EDUCATION_API_USERNAME,
          password: process.env.REACT_APP_EVE_EDUCATION_API_PASSWORD,
        }),
        headers: { "Content-Type": "application/json" },
      };
      axios(loginConfig)
        .then((res) => {
          axios
            .post(
              `${process.env.REACT_APP_API_URL}stock?t=${new Date().getTime()}`,
              segment,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${res.data.token}`,
                },
              }
            )
            .then((response) => {
              setSegmentWithStock(response.data);
              setLoading(false);
              setOpenModal(!openModal);
            })
            .catch((err) => {
              setLoading(false);
              notyf.error("Indisponible");
            });
        })
        .catch((err) => {
          setLoading(false);
          notyf.error("Indisponible");
        });
    } else {
      setOpenModal(false);
      setSegmentWithStock(null);
    }
  };

  return (
    <Fragment>
      <div className={classes.ModalButton} onClick={toggle}>
        {!loading && "Je choisis"}
        {loading && (
          <>
            <span style={{ fontSize: "20px" }}>Disponibilités</span>{" "}
            <img
              src={loader}
              alt="loader"
              style={{ width: "auto", display: "inline-block" }}
            />
          </>
        )}
      </div>

      {segmentWithStock && (
        <ModalProductOption
          segment={segmentWithStock}
          toggle={toggle}
          openModal={openModal}
        />
      )}
    </Fragment>
  );
};

export default ModalButton;
