export const Products = [
  //78 produits - 01.06.2024
  //1
  {
    _id: "5f639b005536b927a8c088f5",
    cursus: "classique",
    section: "maternelle",
    cycle: "Cycle 1",
    name: "Maternelle",
    branches: ["Moyenne Section (MS)", "Grande Section (GS)"],
    price: { month: 60, trimester: 190, year: 540 },
    stock: {
      isOpen: false,
      "Moyenne Section (MS)": false,
      "Grande Section (GS)": false,
    },
    __v: 0,
    registrationFees: 39,
    downPayment: 100,
  },
  //2
  {
    _id: "5f639b275536b927a8c088f6",
    cursus: "classique",
    section: "primaire",
    cycle: "Cycle 2",
    name: "CP",
    price: { month: 105, trimester: 333, year: 945 },
    stock: { morning: true, afternoon: false, isOpen: true },
    __v: 0,
    registrationFees: 39,
    downPayment: 100,
  },
  //3
  {
    _id: "5f639b345536b927a8c088f7",
    cursus: "classique",
    section: "primaire",
    cycle: "Cycle 2",
    name: "CE1",
    price: { month: 105, trimester: 333, year: 945 },
    stock: { morning: true, afternoon: true, isOpen: true },
    __v: 0,
    registrationFees: 39,
    downPayment: 100,
  },
  //4
  {
    _id: "5f639b405536b927a8c088f8",
    cursus: "classique",
    section: "primaire",
    cycle: "Cycle 2",
    name: "CE2",
    price: { month: 105, trimester: 333, year: 945 },
    stock: {
      girls: { morning: true, afternoon: false },
      boys: { morning: false, afternoon: false },
      isOpen: false,
    },
    __v: 0,
    registrationFees: 39,
    downPayment: 100,
  },
  //5
  {
    _id: "5f639b4b5536b927a8c088f9",
    cursus: "classique",
    section: "primaire",
    cycle: "Cycle 3",
    name: "CM1",
    price: { month: 130, trimester: 412, year: 1170 },
    stock: {
      girls: { morning: true, afternoon: false },
      boys: { morning: false, afternoon: false },
      isOpen: false,
    },
    __v: 0,
    registrationFees: 39,
    downPayment: 100,
  },
  //6
  {
    _id: "5f639b565536b927a8c088fa",
    cursus: "classique",
    section: "primaire",
    cycle: "Cycle 3",
    name: "CM2",
    price: { month: 130, trimester: 412, year: 1170 },
    stock: {
      girls: { morning: true, afternoon: true },
      boys: { morning: false, afternoon: false },
      isOpen: true,
    },
    __v: 0,
    registrationFees: 39,
    downPayment: 100,
  },
  //7
  {
    _id: "5f639b615536b927a8c088fb",
    cursus: "classique",
    section: "college",
    cycle: "Cycle 3",
    name: "6eme",
    abrev: "6<sup>e</sup>",
    price: { month: 130, trimester: 412, year: 1170 },
    stock: {
      girls: { morning: false, afternoon: false },
      boys: { morning: true, afternoon: true },
      isOpen: true,
    },
    __v: 0,
    registrationFees: 39,
    downPayment: 100,
  },
  //8
  {
    _id: "5f639b6a5536b927a8c088fc",
    cursus: "classique",
    section: "college",
    cycle: "Cycle 4",
    name: "5eme",
    abrev: "5<sup>e</sup>",
    price: { month: 145, trimester: 459, year: 1305 },
    stock: {
      girls: { morning: true, afternoon: false },
      boys: { morning: false, afternoon: false },
      arabe: {
        girls: { morning: true, afternoon: false },
        boys: { morning: false, afternoon: true },
      },
      espagnol: {
        girls: { morning: true, afternoon: true },
        boys: { morning: false, afternoon: true },
      },
      isOpen: true,
    },
    __v: 0,
    registrationFees: 39,
    downPayment: 100,
  },
  //9
  {
    _id: "5f639b745536b927a8c088fd",
    cursus: "classique",
    section: "college",
    cycle: "Cycle 4",
    name: "4eme",
    abrev: "4<sup>e</sup>",
    price: { month: 145, trimester: 459, year: 1305 },
    stock: {
      girls: { morning: false, afternoon: true },
      boys: { morning: true, afternoon: false },
      arabe: {
        girls: { morning: true, afternoon: true },
        boys: { morning: true, afternoon: true },
      },
      espagnol: {
        girls: { morning: false, afternoon: true },
        boys: { morning: true, afternoon: true },
      },
      isOpen: true,
    },
    __v: 0,
    registrationFees: 39,
    downPayment: 100,
  },
  //10
  {
    _id: "5f639b7f5536b927a8c088fe",
    cursus: "classique",
    section: "college",
    cycle: "Cycle 4",
    name: "3eme",
    abrev: "3<sup>e</sup>",
    price: { month: 165, trimester: 523, year: 1485 },
    stock: {
      girls: { morning: false, afternoon: true },
      boys: { morning: true, afternoon: true },
      arabe: {
        girls: { morning: false, afternoon: true },
        boys: { morning: false, afternoon: true },
      },
      espagnol: {
        girls: { morning: true, afternoon: true },
        boys: { morning: true, afternoon: true },
      },
      isOpen: true,
    },
    __v: 0,
    registrationFees: 39,
    downPayment: 100,
  },
  //11
  {
    _id: "5f639b8a5536b927a8c088ff",
    cursus: "classique",
    section: "lycee",
    cycle: "Lycée",
    name: "Seconde",
    abrev: "2<sup>de</sup>",
    price: { month: 185, trimester: 586, year: 1665 },
    stock: { girls: false, boys: true, isOpen: true },
    __v: 0,
    registrationFees: 39,
    downPayment: 100,
  },
  //12
  {
    _id: "5f639b965536b927a8c08900",
    cursus: "classique",
    section: "lycee",
    cycle: "Lycée",
    name: "Première",
    abrev: "1<sup>re</sup>",
    price: { month: 219, trimester: 694, year: 1971 },
    branches: [
      "Première générale",
      "Première STMG",
      "Première ST2S",
      "Première STI2D",
    ],
    generalOptions: [
      "Sciences de la Vie et de la Terre",
      "Mathématiques",
      "Physique-Chimie",
      "Sciences Économiques et Sociales",
      "Numérique et Sciences Informatiques",
      "Langues, Littératures et Cultures Étrangères",
      "Histoire-Géographie, Géopolitique, Sciences Politiques",
    ],
    stock: {
      girls: true,
      boys: true,
      "Première générale": { girls: false, boys: true },
      "Première STMG": { girls: true, boys: true },
      "Première ST2S": { girls: false, boys: false },
      "Première STI2D": { girls: false, boys: false },
      Mathématiques: { girls: false, boys: true },
      "Physique-Chimie": { girls: false, boys: true },
      "Sciences de la Vie et de la Terre": { girls: false, boys: true },
      "Sciences Économiques et Sociales": { girls: true, boys: true },
      "Numérique et Sciences Informatiques": { girls: false, boys: true },
      "Langues, Littératures et Cultures Étrangères": {
        girls: true,
        boys: false,
      },
      "Histoire-Géographie, Géopolitique, Sciences Politiques": {
        girls: false,
        boys: false,
      },
      isOpen: true,
    },
    __v: 0,
    registrationFees: 39,
    downPayment: 100,
  },
  //13
  {
    _id: "5f639ba05536b927a8c08901",
    cursus: "classique",
    section: "lycee",
    cycle: "Lycée",
    name: "Terminale",
    abrev: "T<sup>le</sup>",
    price: { month: 219, trimester: 694, year: 1971 },
    branches: ["Terminale générale", "Terminale STMG", "Terminale ST2S"],
    generalOptions: [
      {
        girls: [
          "Histoire-Géographie, Géopolitique, Sciences Politiques",
          "Langues, Littératures et Cultures Étrangères",
          "Sciences Économiques et Sociales",
          "Mathématiques",
          "Numérique et Sciences Informatiques",
        ],
        boys: [
          "Mathématiques",
          "Numérique et Sciences Informatiques",
          "Physique-Chimie",
          "Sciences Économiques et Sociales",
        ],
      },
    ],
    stock: {
      girls: true,
      boys: true,
      "Terminale générale": { girls: true, boys: false },
      "Terminale ST2S": { girls: true, boys: true },
      "Terminale STMG": { girls: false, boys: true },
      "Langues, Littératures et Cultures Étrangères": {
        girls: true,
      },
      "Physique-Chimie": {
        boys: false,
      },
      isOpen: true,
    },
    __v: 0,
    registrationFees: 39,
    downPayment: 100,
  },
  //14
  {
    _id: "double-01",
    cursus: "double",
    section: "double-cursus-Primaire",
    cycle: "Cycle 2",
    name: "CP",
    price: {
      month: 174,
      trimester: 551,
      year: 1566,
    },
    stock: { isOpen: true },
    __v: 0,
    registrationFees: 39,
    downPayment: 100,
  },
  //15
  {
    _id: "double-02",
    cursus: "double",
    section: "double-cursus-Primaire",
    cycle: "Cycle 2",
    name: "CE1",
    price: { month: 174, trimester: 551, year: 1566 },
    stock: { isOpen: true },
    __v: 0,
    registrationFees: 39,
    downPayment: 100,
  },
  //16
  {
    _id: "double-03",
    cursus: "double",
    section: "double-cursus-Primaire",
    cycle: "Cycle 2",
    name: "CE2",
    price: { month: 174, trimester: 551, year: 1566 },
    stock: { girls: true, boys: true, isOpen: true },
    __v: 0,
    registrationFees: 39,
    downPayment: 100,
  },
  //17
  {
    _id: "double-04",
    cursus: "double",
    section: "double-cursus-Primaire",
    cycle: "Cycle 3",
    name: "CM1",
    price: { month: 210, trimester: 665, year: 1890 },
    stock: { girls: false, boys: false, isOpen: false },
    __v: 0,
    registrationFees: 39,
    downPayment: 100,
  },
  //18
  {
    _id: "double-05",
    cursus: "double",
    section: "double-cursus-Primaire",
    cycle: "Cycle 3",
    name: "CM2",
    price: { month: 210, trimester: 665, year: 1890 },
    stock: { girls: true, boys: false, isOpen: true },
    __v: 0,
    registrationFees: 39,
    downPayment: 100,
  },
  //19
  {
    _id: "double-06",
    cursus: "double",
    section: "double-cursus-Primaire",
    cycle: "Cycle 1",
    name: "1re année",
    abrev: "1<sup>re</sup> année",
    price: { month: 190, trimester: 602, year: 1710 },
    stock: { isOpen: false },
    __v: 0,
    registrationFees: 39,
    downPayment: 100,
  },
  //20
  {
    _id: "double-07",
    cursus: "double",
    section: "double-cursus-Primaire",
    cycle: "Cycle 1",
    name: "2e année",
    abrev: "2<sup>e</sup> année",
    price: { month: 190, trimester: 602, year: 1710 },
    stock: { isOpen: true },
    __v: 0,
    registrationFees: 39,
    downPayment: 100,
  },
  //21
  {
    _id: "double-08",
    cursus: "double",
    section: "double-cursus-Primaire",
    cycle: "Cycle 2",
    name: "3e année",
    abrev: "3<sup>e</sup> année",
    price: { month: 210, trimester: 665, year: 1890 },
    stock: { isOpen: false },
    __v: 0,
    registrationFees: 39,
    downPayment: 100,
  },
  //22
  {
    _id: "double-09",
    cursus: "double",
    section: "double-cursus-Primaire",
    cycle: "Cycle 2",
    name: "4e année",
    abrev: "4<sup>e</sup> année",
    price: { month: 210, trimester: 665, year: 1890 },
    stock: { isOpen: false },
    __v: 0,
    registrationFees: 39,
    downPayment: 100,
  },
  //23
  {
    _id: "double-10",
    cursus: "double",
    section: "double-cursus-Primaire",
    cycle: "Cycle 3",
    name: "5e année",
    abrev: "5<sup>e</sup> année",
    price: { month: 230, trimester: 728, year: 2070 },
    stock: { isOpen: true },
    __v: 0,
    registrationFees: 39,
    downPayment: 100,
  },
  //24
  {
    _id: "double-11",
    cursus: "double",
    section: "double-cursus-Primaire",
    cycle: "Cycle 3",
    name: "6e année",
    abrev: "6<sup>e</sup> année",
    price: { month: 230, trimester: 728, year: 2070 },
    stock: { isOpen: false },
    __v: 0,
    registrationFees: 39,
    downPayment: 100,
  },
  //25
  {
    _id: "double-12",
    cursus: "double",
    section: "double-cursus-Collège",
    cycle: "Cycle 3",
    name: "6eme",
    abrev: "6<sup>e</sup>",
    price: { month: 210, trimester: 665, year: 1890 },
    stock: { girls: false, boys: true, isOpen: false },
    __v: 0,
    registrationFees: 39,
    downPayment: 100,
  },
  //26
  {
    _id: "double-13",
    cursus: "double",
    section: "double-cursus-Collège",
    cycle: "Cycle 4",
    name: "5eme",
    abrev: "5<sup>e</sup>",
    price: { month: 215, trimester: 681, year: 1935 },
    stock: { girls: false, boys: false, isOpen: false },
    __v: 0,
    registrationFees: 39,
    downPayment: 100,
  },
  //27
  {
    _id: "double-14",
    cursus: "double",
    section: "double-cursus-Collège",
    cycle: "Cycle 4",
    name: "4eme",
    abrev: "4<sup>e</sup>",
    price: { month: 215, trimester: 681, year: 1935 },
    stock: { girls: true, boys: true, isOpen: true },
    __v: 0,
    registrationFees: 39,
    downPayment: 100,
  },
  //28
  {
    _id: "double-15",
    cursus: "double",
    section: "double-cursus-Collège",
    cycle: "Cycle 4",
    name: "3eme",
    abrev: "3<sup>e</sup>",
    price: { month: 220, trimester: 697, year: 1980 },
    stock: { girls: true, boys: true, isOpen: true },
    __v: 0,
    registrationFees: 39,
    downPayment: 100,
  },
  //29
  {
    _id: "double-16",
    cursus: "double",
    section: "double-cursus-Lycée",
    cycle: "Lycée",
    name: "Seconde",
    abrev: "2<sup>de</sup>",
    price: { month: 225, trimester: 713, year: 2025 },
    stock: { girls: true, boys: true, isOpen: false },
    __v: 0,
    registrationFees: 39,
    downPayment: 100,
  },
  //30
  {
    _id: "double-17",
    cursus: "double",
    name: "Première",
    abrev: "1<sup>re</sup>",
    section: "double-cursus-Lycée",
    cycle: "Lycée",
    price: { month: 274, trimester: 868, year: 2466 },
    branches: [
      "Première générale",
      "Première STMG",
      "Première ST2S",
      "Première STI2D",
    ],
    generalOptions: [
      "Sciences de la Vie et de la Terre",
      "Mathématiques",
      "Physique-Chimie",
      "Sciences Économiques et Sociales",
      "Numérique et Sciences Informatiques",
      "Langues, Littératures et Cultures Étrangères",
      "Histoire-Géographie, Géopolitique, Sciences Politiques",
    ],
    stock: {
      girls: true,
      boys: true,
      "Première générale": { girls: false, boys: true },
      "Première ST2S": { girls: false, boys: false },
      "Première STMG": { girls: true, boys: false },
      "Première STI2D": { girls: false, boys: false },
      Mathématiques: { girls: false, boys: true },
      "Physique-Chimie": { girls: false, boys: true },
      "Sciences de la Vie et de la Terre": { girls: false, boys: true },
      "Sciences Économiques et Sociales": { girls: true, boys: true },
      "Numérique et Sciences Informatiques": { girls: false, boys: true },
      "Langues, Littératures et Cultures Étrangères": {
        girls: true,
        boys: false,
      },
      "Histoire-Géographie, Géopolitique, Sciences Politiques": {
        girls: false,
        boys: false,
      },
      isOpen: true,
    },
    __v: 0,
    registrationFees: 39,
    downPayment: 100,
  },
  //31
  {
    _id: "double-18",
    cursus: "double",
    section: "double-cursus-Lycée",
    cycle: "Lycée",
    name: "Terminale",
    abrev: "T<sup>le</sup>",
    price: { month: 274, trimester: 868, year: 2466 },
    branches: ["Terminale générale", "Terminale STMG", "Terminale ST2S"],
    generalOptions: [
      {
        girls: [
          "Histoire-Géographie, Géopolitique, Sciences Politiques",
          "Langues, Littératures et Cultures Étrangères",
          "Sciences Économiques et Sociales",
          "Mathématiques",
          "Numérique et Sciences Informatiques",
        ],
        boys: [
          "Mathématiques",
          "Numérique et Sciences Informatiques",
          "Physique-Chimie",
          "Sciences Économiques et Sociales",
        ],
      },
    ],
    stock: {
      girls: true,
      boys: true,
      "Terminale générale": { girls: true, boys: false },
      "Terminale ST2S": { girls: true, boys: true },
      "Terminale STMG": { girls: false, boys: true },
      "Langues, Littératures et Cultures Étrangères": {
        girls: true,
      },
      "Physique-Chimie": {
        boys: false,
      },
      isOpen: true,
    },
    __v: 0,
    registrationFees: 39,
    downPayment: 100,
  },
  //32
  {
    _id: "bilingue-01",
    cursus: "bilingue",
    section: "bilingue-primaire",
    cycle: "Cycle 2",
    name: "CP",
    price: { month: 209, trimester: 662, year: 1881 },
    stock: { isOpen: false },
    __v: 0,
    registrationFees: 39,
    downPayment: 100,
  },
  //33
  {
    _id: "bilingue-02",
    cursus: "bilingue",
    section: "bilingue-primaire",
    cycle: "Cycle 2",
    name: "CE1",
    price: { month: 209, trimester: 662, year: 1881 },
    stock: { isOpen: true },
    __v: 0,
    registrationFees: 39,
    downPayment: 100,
  },
  //34
  {
    _id: "bilingue-03",
    cursus: "bilingue",
    section: "bilingue-primaire",
    cycle: "Cycle 2",
    name: "CE2",
    price: { month: 209, trimester: 662, year: 1881 },
    stock: {
      boys: true,
      girls: true,
      isOpen: false,
    },
    __v: 0,
    registrationFees: 39,
    downPayment: 100,
  },
  //35
  {
    _id: "bilingue-04",
    cursus: "bilingue",
    section: "bilingue-primaire",
    cycle: "Cycle 3",
    name: "CM1",
    price: { month: 249, trimester: 789, year: 2241 },
    stock: {
      girls: true,
      boys: true,
      isOpen: false,
    },
    __v: 0,
    registrationFees: 39,
    downPayment: 100,
  },
  //36
  {
    _id: "bilingue-05",
    cursus: "bilingue",
    section: "bilingue-primaire",
    cycle: "Cycle 3",
    name: "CM2",
    price: { month: 249, trimester: 789, year: 2241 },
    stock: {
      girls: true,
      boys: true,
      isOpen: false,
    },
    __v: 0,
    registrationFees: 39,
    downPayment: 100,
  },
  //37
  {
    _id: "bilingue-06",
    cursus: "bilingue",
    section: "bilingue-primaire",
    cycle: "Cycle 1",
    name: "1re année",
    abrev: "1<sup>re</sup> année",
    price: { month: 230, trimester: 728, year: 2070 },
    stock: { isOpen: false },
    __v: 0,
    registrationFees: 39,
    downPayment: 100,
  },
  //38
  {
    _id: "bilingue-07",
    cursus: "bilingue",
    section: "bilingue-primaire",
    cycle: "Cycle 1",
    name: "2e année",
    abrev: "2<sup>e</sup> année",
    price: { month: 230, trimester: 728, year: 2070 },
    stock: { isOpen: true },
    __v: 0,
    registrationFees: 39,
    downPayment: 100,
  },
  //39
  {
    _id: "bilingue-08",
    cursus: "bilingue",
    section: "bilingue-primaire",
    cycle: "Cycle 2",
    name: "3e année",
    abrev: "3<sup>e</sup> année",
    price: { month: 250, trimester: 792, year: 2250 },
    stock: { isOpen: false },
    __v: 0,
    registrationFees: 39,
    downPayment: 100,
  },
  //40
  {
    _id: "bilingue-09",
    cursus: "bilingue",
    section: "bilingue-primaire",
    cycle: "Cycle 2",
    name: "4e année",
    abrev: "4<sup>e</sup> année",
    price: { month: 250, trimester: 792, year: 2250 },
    stock: { isOpen: false },
    __v: 0,
    registrationFees: 39,
    downPayment: 100,
  },
  //41
  {
    _id: "bilingue-10",
    cursus: "bilingue",
    section: "bilingue-primaire",
    cycle: "Cycle 3",
    name: "5e année",
    abrev: "5<sup>e</sup> année",
    price: { month: 270, trimester: 855, year: 2430 },
    stock: { isOpen: true },
    __v: 0,
    registrationFees: 39,
    downPayment: 100,
  },
  //42
  {
    _id: "bilingue-11",
    cursus: "bilingue",
    section: "bilingue-primaire",
    cycle: "Cycle 3",
    name: "6e année",
    abrev: "6<sup>e</sup> année",
    price: { month: 270, trimester: 855, year: 2430 },
    stock: { isOpen: false },
    __v: 0,
    registrationFees: 39,
    downPayment: 100,
  },
  //43
  {
    _id: "bilingue-12",
    cursus: "bilingue",
    section: "bilingue-college",
    cycle: "Cycle 3",
    name: "6eme",
    abrev: "6<sup>e</sup>",
    price: { month: 249, trimester: 789, year: 2241 },
    stock: {
      girls: false,
      boys: true,
      isOpen: false,
    },
    __v: 0,
    registrationFees: 39,
    downPayment: 100,
  },
  //44
  {
    _id: "bilingue-13",
    cursus: "bilingue",
    section: "bilingue-college",
    cycle: "Cycle 4",
    name: "5eme",
    abrev: "5<sup>e</sup>",
    price: { month: 259, trimester: 820, year: 2331 },
    stock: {
      girls: true,
      boys: false,
      arabe: { girls: true, boys: true },
      espagnol: { girls: true, boys: true },
      isOpen: true,
    },
    __v: 0,
    registrationFees: 39,
    downPayment: 100,
  },
  //45
  {
    _id: "bilingue-14",
    cursus: "bilingue",
    section: "bilingue-college",
    cycle: "Cycle 4",
    name: "4eme",
    abrev: "4<sup>e</sup>",
    price: { month: 259, trimester: 820, year: 2331 },
    stock: {
      girls: true,
      boys: true,
      arabe: { girls: true, boys: true },
      espagnol: { girls: true, boys: true },
      isOpen: true,
    },
    __v: 0,
    registrationFees: 39,
    downPayment: 100,
  },
  //46
  {
    _id: "bilingue-15",
    cursus: "bilingue",
    section: "bilingue-college",
    cycle: "Cycle 4",
    name: "3eme",
    abrev: "3<sup>e</sup>",
    price: { month: 269, trimester: 852, year: 2421 },
    stock: {
      girls: false,
      boys: true,
      arabe: { girls: true, boys: true },
      espagnol: { girls: true, boys: true },
      isOpen: true,
    },
    __v: 0,
    registrationFees: 39,
    downPayment: 100,
  },
  //47
  {
    _id: "bilingue-16",
    cursus: "bilingue",
    section: "bilingue-lycee",
    cycle: "Lycée",
    name: "Seconde",
    abrev: "2<sup>de</sup>",
    price: { month: 279, trimester: 884, year: 2511 },
    stock: { girls: false, boys: true, isOpen: true },
    __v: 0,
    registrationFees: 39,
    downPayment: 100,
  },
  //48
  {
    _id: "bilingue-17",
    cursus: "bilingue",
    section: "bilingue-lycee",
    cycle: "Lycée",
    name: "Première",
    abrev: "1<sup>re</sup>",
    price: { month: 303, trimester: 960, year: 2727 },
    branches: [
      "Première générale",
      "Première STMG",
      "Première ST2S",
      "Première STI2D",
    ],
    generalOptions: [
      "Sciences de la Vie et de la Terre",
      "Mathématiques",
      "Physique-Chimie",
      "Sciences Économiques et Sociales",
      "Numérique et Sciences Informatiques",
      "Langues, Littératures et Cultures Étrangères",
      "Histoire-Géographie, Géopolitique, Sciences Politiques",
    ],
    stock: {
      girls: true,
      boys: true,
      "Première générale": { girls: true, boys: true },
      "Première STMG": { girls: true, boys: true },
      "Première ST2S": { girls: false, boys: false },
      "Première STI2D": { girls: false, boys: false },
      Mathématiques: { girls: false, boys: true },
      "Physique-Chimie": { girls: false, boys: true },
      "Sciences de la Vie et de la Terre": { girls: false, boys: true },
      "Sciences Économiques et Sociales": { girls: true, boys: true },
      "Numérique et Sciences Informatiques": { girls: false, boys: true },
      "Langues, Littératures et Cultures Étrangères": {
        girls: true,
        boys: true,
      },
      "Histoire-Géographie, Géopolitique, Sciences Politiques": {
        girls: false,
        boys: true,
      },
      isOpen: true,
    },
    __v: 0,
    registrationFees: 39,
    downPayment: 100,
  },
  //49
  {
    _id: "bilingue-18",
    cursus: "bilingue",
    section: "bilingue-lycee",
    cycle: "Lycée",
    name: "Terminale",
    abrev: "T<sup>le</sup>",
    price: { month: 303, trimester: 960, year: 2727 },
    branches: ["Terminale générale", "Terminale STMG", "Terminale ST2S"],
    generalOptions: [
      {
        girls: [
          "Histoire-Géographie, Géopolitique, Sciences Politiques",
          "Langues, Littératures et Cultures Étrangères",
          "Sciences Économiques et Sociales",
          "Mathématiques",
          "Numérique et Sciences Informatiques",
        ],
        boys: [
          "Mathématiques",
          "Numérique et Sciences Informatiques",
          "Physique-Chimie",
          "Sciences Économiques et Sociales",
        ],
      },
    ],
    stock: {
      girls: true,
      boys: true,
      "Terminale générale": { girls: true, boys: false },
      "Terminale ST2S": { girls: true, boys: true },
      "Terminale STMG": { girls: false, boys: true },
      "Langues, Littératures et Cultures Étrangères": {
        girls: true,
      },
      "Histoire-Géographie, Géopolitique, Sciences Politiques": {
        girls: true,
      },
      "Sciences Économiques et Sociales": {
        girls: true,
        boys: true,
      },
      Mathématiques: {
        girls: true,
        boys: true,
      },
      "Numérique et Sciences Informatiques": {
        girls: true,
        boys: true,
      },
      "Physique-Chimie": {
        boys: false,
      },
      isOpen: true,
    },
    __v: 0,
    registrationFees: 39,
    downPayment: 100,
  },
  //50
  {
    _id: "belge-01",
    cursus: "belge",
    section: "belge-primaire",
    cycle: "Cycle 1",
    name: "1re année",
    abrev: "1<sup>re</sup> année",
    price: { month: 120, trimester: 380, year: 1080 },
    stock: { isOpen: false },
    __v: 0,
    registrationFees: 39,
    downPayment: 100,
  },
  //51
  {
    _id: "belge-02",
    cursus: "belge",
    section: "belge-primaire",
    cycle: "Cycle 1",
    name: "2e année",
    abrev: "2<sup>e</sup> année",
    price: { month: 120, trimester: 380, year: 1080 },
    stock: { isOpen: true },
    __v: 0,
    registrationFees: 39,
    downPayment: 100,
  },
  //52
  {
    _id: "belge-03",
    cursus: "belge",
    section: "belge-primaire",
    cycle: "Cycle 2",
    name: "3e année",
    abrev: "3<sup>e</sup> année",
    price: { month: 140, trimester: 443, year: 1260 },
    stock: { isOpen: false },
    __v: 0,
    registrationFees: 39,
    downPayment: 100,
  },
  //53
  {
    _id: "belge-04",
    cursus: "belge",
    section: "belge-primaire",
    cycle: "Cycle 2",
    name: "4e année",
    abrev: "4<sup>e</sup> année",
    price: { month: 140, trimester: 443, year: 1260 },
    stock: { isOpen: false },
    __v: 0,
    registrationFees: 39,
    downPayment: 100,
  },
  //54
  {
    _id: "belge-05",
    cursus: "belge",
    section: "belge-primaire",
    cycle: "Cycle 3",
    name: "5e année",
    abrev: "5<sup>e</sup> année",
    price: { month: 160, trimester: 507, year: 1440 },
    stock: { isOpen: true },
    __v: 0,
    registrationFees: 39,
    downPayment: 100,
  },
  //55
  {
    _id: "belge-06",
    cursus: "belge",
    section: "belge-primaire",
    cycle: "Cycle 3",
    name: "6e année",
    abrev: "6<sup>e</sup> année",
    price: { month: 160, trimester: 507, year: 1440 },
    stock: { isOpen: false },
    __v: 0,
    registrationFees: 39,
    downPayment: 100,
  },
  //56
  {
    _id: "6274f33efad67101162bebfe",
    cursus: "carte",
    section: "lycee",
    name: "Tronc Commun",
    cycle: "Lycée",
    abrev: "Tronc Commun - 1<sup>re</sup>/T<sup>le</sup>",
    branches: ["Tronc Commun Première", "Tronc Commun Terminale"],
    price: { month: 179, trimester: 567, year: 1611 },
    category: "tronc",
    stock: {
      "Tronc Commun Première": { girls: true, boys: true },
      "Tronc Commun Terminale": { girls: true, boys: true },
      girls: true,
      boys: true,
      isOpen: true,
    },
    __v: 0,
    registrationFees: 0,
    downPayment: 0,
  },
  //57
  {
    _id: "stage-01",
    cursus: "carte",
    name: "Stage essentiel",
    category: "stage",
    price: { one: "90", two: "160" },
    stock: { isOpen: true },
    __v: 0,
    infos:
      "du lundi au jeudi<br/>choix 1 h ou 2 h / jour<br/><br/><span style='color: #e4256e; font-weight: bold; font-size: 1.2rem'>Français, Mathématiques</span>",
    coursesChoice: [
      "1h/jour : Français",
      "1h/jour : Mathématiques",
      "2h/jour : Français et Mathématiques",
    ],
    levels: ["CP", "CE1", "CE2", "CM1", "CM2", "6eme", "5eme", "4eme", "3eme"],
    registrationFees: 0,
    downPayment: 0,
  },
  //58
  {
    _id: "stage-02",
    cursus: "carte",
    name: "Stage bénéfique",
    category: "stage",
    price: { one: "90", two: "160" },
    __v: 0,
    stock: { isOpen: false },
    infos:
      "du lundi au jeudi<br/>choix 1 h ou 2 h / jour<br/><br/><span style='color: #e4256e; font-weight: bold; font-size: 1.2rem'>Arabe, Coran</span>",
    coursesChoice: [
      "1h/jour : Arabe",
      "1h/jour : Coran",
      "2h/jour : Arabe et Coran",
    ],
    levels: ["CP", "CE1", "CE2", "CM1", "CM2", "6eme", "5eme", "4eme", "3eme"],
    registrationFees: 0,
    downPayment: 0,
  },
  //59
  {
    _id: "stage-03",
    cursus: "carte",
    name: "Stage de langues",
    category: "stage",
    price: { one: "90", two: "160" },
    __v: 0,
    stock: { isOpen: false },
    infos:
      "du lundi au jeudi<br/>choix 1 h ou 2 h / jour<br/><br/><span style='color: #e4256e; font-weight: bold; font-size: 1.2rem'>anglais, arabe, espagnol</span>",
    coursesChoice: [
      "1h/jour : Anglais",
      "1h/jour : Arabe",
      "1h/jour : Espagnol",
      "2h/jour : Anglais et Espagnol",
      "2h/jour : Anglais et Arabe",
      "2h/jour : Espagnol et Arabe",
    ],
    levels: ["CP", "CE1", "CE2", "CM1", "CM2", "6eme", "5eme", "4eme", "3eme"],
    registrationFees: 0,
    downPayment: 0,
  },
  //60
  {
    _id: "60a390a966112b61648051cf",
    cursus: "carte",
    name: "Cours exclusifs - Primaire/collège",
    abrev: "Primaire/Collège",
    price: { hour: "15" },
    infos:
      '<span style="color: #e4256e; font-weight: bold">session de 4 semaines</span><br/><br/>français, maths, histoire-géo<br/>espagnol, anglais, arabe',
    category: "exclusif",
    coursesChoice: [
      "Français",
      "Mathématiques",
      "Histoire-Géographie",
      "Anglais",
      "Espagnol",
      "Arabe",
    ],
    stock: { isOpen: true },
    days: [
      "lundi",
      "mardi",
      "mercredi",
      "jeudi",
      "vendredi",
      "samedi",
      "dimanche",
    ],
    slots: [
      "8 h - 10 h",
      "10 h - 12 h",
      "12 h - 14 h",
      "14 h - 16 h",
      "16 h - 18 h",
      "18 h - 20 h",
    ],
    duration: { "1 h": 1, "2 h": 2, "3 h": 3, "4 h": 4, "5 h": 5 },
    levels: [
      "CP",
      "CE1",
      "CE2",
      "CM1",
      "CM2",
      "6e",
      "5e",
      "4e",
      "3e",
      "1re année (belge)",
      "2e année (belge)",
      "3e année (belge)",
      "4e année (belge)",
      "5e année (belge)",
      "6e année (belge)",
    ],
    registrationFees: 0,
    downPayment: 0,
  },
  //61
  {
    _id: "6329a2c152d8681935a01be6",
    cursus: "carte",
    name: "Cours exclusifs - Lycée",
    abrev: "Lycée",
    price: { hour: "20" },
    infos:
      '<span style="color: #e4256e; font-weight: bold">session de 4 semaines</span><br/><br/>français, maths, histoire-géo<br/>svt, physique-chimie<br/>espagnol, arabe, anglais',
    category: "exclusif",
    coursesChoice: [
      "Français",
      "Mathématiques",
      "Histoire-Géographie",
      "Physique-Chimie",
      "SVT",
      "Anglais",
      "Espagnol",
      "Arabe",
    ],
    stock: { isOpen: true },
    days: [
      "lundi",
      "mardi",
      "mercredi",
      "jeudi",
      "vendredi",
      "samedi",
      "dimanche",
    ],
    slots: [
      "8 h - 10 h",
      "10 h - 12 h",
      "12 h - 14 h",
      "14 h - 16 h",
      "16 h - 18 h",
      "18 h - 20 h",
    ],
    duration: { "1 h": 1, "2 h": 2, "3 h": 3, "4 h": 4, "5 h": 5 },
    levels: ["Seconde", "Première", "Terminale"],
    registrationFees: 0,
    downPayment: 0,
  },
  //62
  {
    _id: "60a390a966112b61648051cf",
    cursus: "carte",
    name: "Cours exclusifs - Université",
    abrev: "Université",
    price: { hour: "25" },
    infos:
      '<span style="color: #e4256e; font-weight: bold">session de 4 semaines</span><br/><br/>français, maths, histoire-géo<br/>svt, physique-chimie<br/>espagnol, anglais, arabe',
    category: "exclusif",
    coursesChoice: [
      "Français",
      "Mathématiques",
      "Histoire-Géographie",
      "Physique-Chimie",
      "SVT",
      "Anglais",
      "Espagnol",
      "Arabe",
    ],
    stock: { isOpen: true },
    days: [
      "lundi",
      "mardi",
      "mercredi",
      "jeudi",
      "vendredi",
      "samedi",
      "dimanche",
    ],
    slots: [
      "8 h - 10 h",
      "10 h - 12 h",
      "12 h - 14 h",
      "14 h - 16 h",
      "16 h - 18 h",
      "18 h - 20 h",
    ],
    duration: { "1 h": 1, "2 h": 2, "3 h": 3, "4 h": 4, "5 h": 5 },
    levels: ["Bac +1", "Bac +2", "Bac +3", "Bac +4", "Bac +5", "> Bac +5"],
    registrationFees: 0,
    downPayment: 0,
  },
  //63
  {
    _id: "633a862e0fc308136a9f2be8",
    cursus: "carte",
    name: "Cours exclusifs - Coran",
    abrev: "Coran",
    price: { hour: "12" },
    infos:
      '<span style="color: #e4256e; font-weight: bold">session de 4 semaines</span>',
    category: "exclusif",
    stock: { isOpen: true },
    days: [
      "lundi",
      "mardi",
      "mercredi",
      "jeudi",
      "vendredi",
      "samedi",
      "dimanche",
    ],
    slots: [
      "8 h - 10 h",
      "10 h - 12 h",
      "12 h - 14 h",
      "14 h - 16 h",
      "16 h - 18 h",
      "18 h - 20 h",
    ],
    duration: { "1 h": 1, "2 h": 2, "3 h": 3, "4 h": 4, "5 h": 5 },
    levels: [
      "CP",
      "CE1",
      "CE2",
      "CM1",
      "CM2",
      "6e",
      "5e",
      "4e",
      "3e",
      "Seconde",
      "Première",
      "Terminale",
      "Université",
      "1re année (belge)",
      "2e année (belge)",
      "3e année (belge)",
      "4e année (belge)",
      "5e année (belge)",
      "6e année (belge)",
    ],
    registrationFees: 0,
    downPayment: 0,
  },
  //64
  {
    _id: "633a862e0fc308136a9f2be8",
    cursus: "carte",
    name: "Cours exclusifs - Sciences religieuses",
    abrev: "Sciences religieuses",
    price: { hour: "15" },
    infos:
      '<span style="color: #e4256e; font-weight: bold">session de 4 semaines</span>',
    category: "exclusif",
    stock: { isOpen: true },
    days: [
      "lundi",
      "mardi",
      "mercredi",
      "jeudi",
      "vendredi",
      "samedi",
      "dimanche",
    ],
    slots: [
      "8 h - 10 h",
      "10 h - 12 h",
      "12 h - 14 h",
      "14 h - 16 h",
      "16 h - 18 h",
      "18 h - 20 h",
    ],
    duration: { "1 h": 1, "2 h": 2, "3 h": 3, "4 h": 4, "5 h": 5 },
    levels: [
      "CP",
      "CE1",
      "CE2",
      "CM1",
      "CM2",
      "6e",
      "5e",
      "4e",
      "3e",
      "Seconde",
      "Première",
      "Terminale",
      "Université",
      "1re année (belge)",
      "2e année (belge)",
      "3e année (belge)",
      "4e année (belge)",
      "5e année (belge)",
      "6e année (belge)",
    ],
    registrationFees: 0,
    downPayment: 0,
  },
  //65
  {
    _id: "63d1b10336c533243dfbb33b",
    cursus: "carte",
    name: "Formule Nouraniya",
    section: "madrassa-nouraniya",
    category: "pole",
    infos: "session de 10 semaines",
    price: { thrice: "49", once: "130" },
    hours: "2",
    stock: { girls: true, boys: true, isOpen: true },
    __v: 0,
    registrationFees: 39,
    downPayment: 0,
  },
  //66
  {
    _id: "63d1a97036c533243dfbb334",
    cursus: "carte",
    name: "Formule Arabe",
    section: "madrassa-arabe",
    category: "pole",
    infos: "session de 30 semaines",
    price: { month: "49", year: "441" },
    hours: "2",
    stock: { girls: true, boys: true, isOpen: true },
    __v: 0,
    registrationFees: 39,
    downPayment: 0,
  },
  //67
  {
    _id: "63d1af4b36c533243dfbb335",
    cursus: "carte",
    name: "Arabe Intensif",
    section: "madrassa-arabe",
    category: "pole",
    infos: "session de 30 semaines",
    price: { month: "89", year: "801" },
    hours: "4",
    stock: { girls: true, boys: true, isOpen: true },
    __v: 0,
    registrationFees: 39,
    downPayment: 0,
  },
  //68
  {
    _id: "63d1afc636c533243dfbb337",
    cursus: "carte",
    name: "Formule Coran",
    section: "madrassa-coran",
    category: "pole",
    infos: "session de 30 semaines",
    price: { month: "39", year: "351" },
    hours: "2",
    stock: { girls: true, boys: true, isOpen: true },
    __v: 0,
    registrationFees: 39,
    downPayment: 0,
  },
  //69
  {
    _id: "63d1b01836c533243dfbb338",
    cursus: "carte",
    name: "Coran Intensif",
    section: "madrassa-coran",
    category: "pole",
    infos: "session de 30 semaines",
    price: { month: "59", year: "531" },
    hours: "4",
    stock: { girls: true, boys: true, isOpen: true },
    __v: 0,
    registrationFees: 39,
    downPayment: 0,
  },
  //70
  {
    _id: "63d1b04e36c533243dfbb339",
    cursus: "carte",
    name: "Formule Complète",
    section: "madrassa-complet",
    category: "pole",
    infos:
      "<b>CP ET CE1 <u>UNIQUEMENT</u></b><br/><br/>2 h Arabe - 2 h Coran - 1 h sciences religieuses<br/><br/>session de 30 semaines",
    price: { month: "99", year: "891" },
    hours: "5",
    stock: { girls: true, boys: true, isOpen: true },
    __v: 0,
    registrationFees: 39,
    downPayment: 0,
  },
  //71
  {
    _id: "63d1b0bc36c533243dfbb33a",
    cursus: "carte",
    name: "Formule Intensive",
    section: "madrassa-complet",
    category: "pole",
    infos:
      "<b>À PARTIR DU CE2</b><br/><br/>4 h Arabe - 4 h Coran - 2 h sciences religieuses<br/><br/>session de 30 semaines",
    price: { month: "149", year: "1341" },
    hours: "10",
    stock: { girls: true, boys: true, isOpen: true },
    __v: 0,
    registrationFees: 39,
    downPayment: 0,
  },
  //72
  {
    _id: "621e02e1f8b94548244a44b6",
    cursus: "atelier",
    name: "Ateliers de langues",
    price: { once: "99" },
    stock: { isOpen: true },
    infos: "2 h /semaine - Durée : 10 semaines",
    coursesChoice: ["Anglais", "Allemand", "Arabe", "Espagnol"],
    levels: ["Débutant", "Intermédiaire", "Avancé"],
    days: ["lundi/jeudi", "lundi/vendredi", "mardi/jeudi", "mardi/vendredi"],
    slots: ["17 h - 18 h", "18 h - 19 h"],
    registrationFees: 0,
    downPayment: 0,
  },
  //73
  {
    _id: "621e0353f8b94548244a44b7",
    cursus: "atelier",
    name: "Ateliers scientifiques",
    price: { once: "199" },
    stock: { isOpen: true },
    infos: "1 h / semaine de septembre à juin<br/>durée : 30 semaines",
    days: ["mercredi", "samedi"],
    registrationFees: 0,
    downPayment: 0,
  },
  //74
  {
    _id: "621e0373f8b94548244a44b8",
    cursus: "atelier",
    name: "Ateliers d'éloquence",
    price: { once: "199" },
    stock: { isOpen: true },
    infos: "1 h / semaine de septembre à juin<br/>durée : 30 semaines",
    days: ["mercredi", "samedi"],
    registrationFees: 0,
    downPayment: 0,
  },
  //75
  {
    _id: "5f71d4f21fa4603d5471e388",
    cursus: "pec",
    name: "PEC FRANÇAIS",
    infos: "Français",
    price: { once: "220" },
    stock: { isOpen: true },
    levels: ["CP", "CE1", "CE2", "CM1", "CM2"],
    __v: 0,
    registrationFees: 0,
  },
  //76
  {
    _id: "5f71d53e1fa4603d5471e389",
    cursus: "pec",
    name: "PEC MATHS",
    infos: "Mathématiques",
    price: { once: "220" },
    stock: { isOpen: true },
    levels: ["CP", "CE1", "CE2", "CM1", "CM2"],
    __v: 0,
    registrationFees: 0,
  },
  //77
  {
    _id: "5f71d5931fa4603d5471e38a",
    cursus: "pec",
    name: "PEC COMPLET",
    infos: "Français, mathématiques, QLM",
    price: { once: "600", twice: "310" },
    stock: { isOpen: true },
    levels: ["CP", "CE1", "CE2", "CM1", "CM2"],
    __v: 0,
    registrationFees: 0,
  },
  //78
  {
    _id: "6274f05afad67101162bebfd",
    cursus: "pec",
    name: "PEC CEB BELGE",
    infos: "Français, mathématiques",
    price: { once: "600", twice: "310" },
    stock: { isOpen: true },
    __v: 0,
    registrationFees: 0,
  },
];
